import { Topic } from '.'

const questions = [
  {question: "What is your favorite travel destination, and why?"},
  {question: "What is one thing you always pack in your suitcase when traveling?"},
  {question: "Do you have any funny or memorable experiences from a past trip? Share them with us!"},
  {question: "What is the most beautiful place you've ever visited? Why did it leave such an impression on you?"},
  {question: "Do you have a favorite travel companion? Who is it, and why do you enjoy traveling with them?"},
  {question: "What is the most adventurous activity you've ever done while traveling? Would you do it again?"},
  {question: "Have you ever visited a place that was completely different from your home culture? How did you adapt, and what did you learn from the experience?"},
  {question: "What is one thing you wish you had known before embarking on your last trip?"},
  {question: "Do you have a favorite type of accommodation when traveling (e.g., hotels, hostels, Airbnb)? Why do you prefer it?"},
  {question: "Have you ever visited a place that was famous for its history or landmarks? How did you experience these aspects of the location?"},
  {question: "Do you prefer to travel during peak season or off-season? Why?"},
  {question: "What is the most memorable souvenir you've ever brought back from a trip? Why did you choose it?"},
  {question: "Have you ever had to navigate a language barrier while traveling? How did you handle it?"},
  {question: "What is the most important thing you pack in your suitcase when traveling for an extended period of time? Why is it so essential?"},
  {question: "Have you ever gone on a road trip? If so, where did you go, and what was your favorite part of the journey?"},
  {question: "Have you ever visited a foreign country that spoke a language you didn't speak? How did you navigate communication challenges?"},
  {question: "Have you ever taken a train or bus trip for an extended period of time? Where did you go, and what was your experience like?"},
  {question: "Have you ever tried any unique or exotic foods during your travels? What were they, and how did you like them?"},
  {question: "Have you ever taken a boat tour or gone kayaking in a new location? Share your experience!"},
  {question: "Do you prefer to plan out every detail of your trip in advance, or do you like to wing it and see where the day takes you?"},
  {question: "Have you ever met anyone interesting or unique while traveling? Share their story!"},
  {question: "What is the longest trip you've ever taken? How did you prepare for it, and what were some of your favorite moments?"},
  {question: "Have you ever tried a new sport or activity while traveling (e.g., surfing, skiing, bungee jumping)? Share your experience!"},
  {question: "Do you have any favorite travel bloggers or influencers who inspire your wanderlust? Who are they, and why do you enjoy their content?"},
  {question: "Do you have a passport? If so, how many stamps does it have?"}
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Travel_and_Geography: Topic = {
  topic: 'Travel, and Geography',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}