// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

const questions = [
  {
    question: "How do you decide which books to read next? Do you follow specific authors or genres, or do you prefer to explore new titles?"
},
{
    question: "What's the most recent book you've read, and what did you think of it? Did it meet your expectations?"
},
{
    question: "What's the most memorable character you've encountered in a book, and why did they stand out to you?"
},
{
    question: "Have you ever read a book that challenged your beliefs or perspectives? Which one, and how did it impact you?"
},
{
    question: "What's the most interesting thing you've learned from a book recently? Did it surprise you, or did it confirm something you already suspected?"
},
{
    question: "Have you ever reread a book, and if so, which one and why? Did you notice anything new upon second reading?"
},
{
    question: "What's the most underrated book you've ever read, and why do you think it deserves more recognition?"
},
{
    question: "What's the longest book you've ever read, and how did you find the experience? Did you enjoy the depth and complexity of the story?"
},
{
    question: "How do you approach reading books from different cultures or time periods? Do you have any favorite authors or works from these categories?"
},
{
    question: "Have you ever written a book review or shared your thoughts on social media about a book you've read? If so, which one and what did you think of the experience?"
},
{
    question: "What's the most memorable setting you've encountered in a book, and why did it stand out to you?"
},
{
    question: "What's the most thought-provoking question you've encountered in a book, and how did it make you think differently about the subject matter?"
},
{
    question: "How do you approach reading books from different genres? Do you have any favorites or ones you avoid?"
},
{
    question: "Have you ever read a book that made you feel uncomfortable or challenged your beliefs? Which one, and how did you react to it?"
},
{
    question: "What's the most unique book you've ever read, and why was it standout to you?"
},
{
    question: "What's the most challenging book you've ever read, and how did you find the experience? Did you feel fulfilled after completing it?"
},
{
    question: "How do you approach reading books that have been adapted into movies or TV shows? Do you prefer to read the book first, or watch the adaptation first and then read the book?"
},
{
    question: "Have you ever read a book that made you feel nostalgic or transported to a different time and place? Which one, and why did it have that effect on you?"
},
{
    question: "What's the most memorable character development you've encountered in a book recently? Did it enhance your reading experience, or did it detract from it?"
},
{
    question: "How do you approach reading books by authors who are no longer alive? Do you have any favorites or ones you avoid?"
},
{
    question: "What's the most unique writing style or narrative device you've encountered in a book recently? Did it enhance your reading experience, or did it detract from it?"
},
{
    question: "How do you approach reading books that are part of a particular genre or category (e.g., science fiction, fantasy, mystery, etc.)? Do you have any favorites or ones you avoid?"
},
{
    question: "What's the most interesting way you've encountered a book recently (e.g., through social media, a recommendation from a friend, etc.)? Did it catch your attention, and why did you decide to read it?"
},
{
    question: "What's the most memorable scene or passage you've encountered in a book recently? Did it stand out to you, and why did it make an impression on you?"
},
{
    question: "What's the most interesting way you've encountered a character recently? Did they stand out to you, and why did you find them memorable?"
},
{
    question: "What's the most memorable setting you've encountered in a book recently? Did it transport you to another time or place, and why did it stand out to you?"
},
{
    question: "What's the most interesting dialogue or monologue you've encountered in a book recently? Did it reveal something new about the characters or plot, or did it simply add depth to the story?"
},
{
    question: "How do you approach reading books by authors from different backgrounds and experiences? Do you have any favorites or ones you avoid?"
},
{
    question: "What's the most interesting way you've encountered a character recently? Did they stand out to you, and why did you find them memorable?"
},
{
    question: "What's the most unique writing style or narrative device you've encountered in a book recently? Did it enhance your reading experience, or did it detract from it?"
},
{
    question: "How do you approach reading books with complex plots or storylines? Do you prefer books that are straightforward and easy to follow, or ones that require more effort to understand?"
},
{
    question: "Do you prefer physical books or e-books? Why or why not?"
},
{
    question: "How do you approach reading non-fiction versus fiction? Do you prefer one over the other, or do you enjoy both equally?"
},
{
    question: "Have you ever participated in a book club or discussion group? If so, what was your favorite part about it?"
},
{
    question: "What's the shortest book you've ever read, and how did you find the experience? Did you enjoy the concise nature of the story?"
},
{
    question: "How do you feel about the role of literature in society today? Do you think it's still an important aspect of culture, or has its significance dwindled over time?"
},
{
    question: "What's your favorite book from childhood, and why did it leave a lasting impression on you?"
},
{
    question: "Have you ever read a book that changed your perspective on something? What was the book, and what did it teach you?"
},
{
    question: "Have you ever read a book that made you laugh out loud or cry uncontrollably? Which one, and why did it have such an effect on you?"
},
{
    question: "How do you feel about audiobooks versus reading physical books? Do you prefer one over the other, or do you enjoy both equally?"
},
{
    question: "How do you feel about classics versus contemporary literature? Do you have a preference for one over the other?"
},
{
    question: "How do you approach reading books by new authors versus established ones? Do you have any preferences or biases?"
},
{
    question: "How do you feel about the impact of technology on reading habits and the publishing industry as a whole? Do you think e-books and audiobooks have helped or hindered the world of literature?"
},
{
    question: "What's the most interesting literary device or technique you've encountered in a book recently? Did it enhance your reading experience, or did it detract from it?"
},
{
    question: "How do you feel about the role of authors in society today? Do you think they have a responsibility to address certain issues or topics through their writing?"
},
{
    question: "How do you approach reading books by authors from different backgrounds and experiences? Do you have any favorites or ones you avoid?"
},
{
    question: "Have you ever participated in a literary event or festival? If so, which one, and what was your favorite part about it?"
},
{
    question: "What's the most underappreciated author or book you think deserves more recognition? Why do you think they are overlooked, and what makes their work special?"
},
{
    question: "How do you approach reading books that are part of a series or franchise? Do you prefer to start from the beginning, or can you jump in anywhere and still enjoy the story?"
},
{
    question: "How do you approach reading books with complex or abstract themes? Do you prefer books that tackle heavy issues head-on, or ones that handle them more subtly?"
},
{
    question: "What's the most interesting dialogue or monologue you've encountered in a book recently? Did it reveal something new about the characters or plot, or did it simply add depth to the story?"
},
{
    question: "What's the most memorable plot twist or surprise you've encountered in a book recently? Did it enhance your reading experience, or did it detract from it?"
},
{
    question: "How do you approach reading books that are part of a series or ongoing storyline? Do you prefer to start from the beginning, or can you jump in anywhere and still enjoy the story?"
},
{
    question: "How do you approach reading books by authors who are from different cultures or backgrounds than your own? Do you have any favorites or ones you avoid?"
},
{
    question: "How do you approach reading books with complex themes or ideas? Do you prefer books that tackle heavy issues head-on, or ones that handle them more subtly?"
},
{
    question: "How do you approach reading books by authors who are no longer alive? Do you have any favorites or ones you avoid?"
},
{
    question: "What's the most unique writing style or narrative device you've encountered in a book recently? Did it enhance your reading experience, or did it detract from it?"
},
{
    question: "How do you approach reading books with complex plots or storylines? Do you prefer books that are straightforward and easy to follow, or ones that require more effort to understand?"
},
{
    question: "How do you approach reading books that are part of a particular genre or category (e.g., science fiction, fantasy, mystery, etc.)? Do you have any favorites or ones you avoid?"
},
{
    question: "What's the most memorable character development you've encountered in a book recently? Did it enhance your reading experience, or did it detract from it?"
},
{
    question: "How do you approach reading books with complex themes or ideas? Do you prefer books that tackle heavy issues head-on, or ones that handle them more subtly?"
},
{
    question: "How do you approach reading books by authors who are no longer alive? Do you have any favorites or ones you avoid?"
},
{
    question: "What's the most memorable setting you've encountered in a book recently? Did it transport you to another time or place, and why did it stand out to you?"
},
{
    question: "Have you ever read a book that made you want to try something new or explore a different hobby? Which one, and what did you end up trying out?"
},
{
    question: "Have you ever read a book that made you want to learn more about a particular subject or topic? Which one, and what did you end up learning?"
},
{
    question: "Have you ever read a book that made you want to travel to a different place or explore a new culture? Which one, and what did you end up doing as a result?"
},
{
    question: "Have you ever read a book that made you want to try a new hobby or activity? Which one, and what did you end up trying out?"
},
{
    question: "Have you ever read a book that made you want to learn more about a particular topic or subject? Which one, and what did you end up learning?"
},
{
    question: "Have you ever read a book that made you want to try a new type of cuisine or food? Which one, and what did you end up trying out?"
},
{
    question: "Have you ever read a book that made you want to explore a different hobby or activity? Which one, and what did you end up trying out?"
},
{
    question: "Have you ever read a book that made you want to try something new or explore a different hobby? Which one, and what did you end up trying out?"
},
{
    question: "Have you ever read a book that made you feel inspired or motivated to take action? Which one, and what did you end up doing as a result?"
},
{
    question: "Have you ever read a book that made you want to explore a different hobby or activity? Which one, and what did you end up trying out?"
},
{
    question: "Have you ever read a book that made you want to travel to a different place or explore a new culture? Which one, and what did you end up doing as a result?"
},
{
    question: "Have you ever read a book that made you feel inspired or motivated to take action? Which one, and what did you end up doing as a result?"
},
{
    question: "Have you ever read a book that was recommended to you by a friend or family member? Which one, and how did you like it?"
},
{
    question: "Have you ever read a book that made you feel inspired or motivated to take action? Which one, and what did you end up doing as a result?"
},
{
    question: "Have you ever read a book that made you want to travel to a different place or explore a new culture? Which one, and what did you end up doing as a result?"
},
{
    question: "Have you ever read a book that made you want to try a new type of cuisine or food? Which one, and what did you end up trying out?"
},
{
    question: "Have you ever read a book that made you want to try something new or explore a different hobby? Which one, and what did you end up trying out?"
}
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
  return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Books_and_Literature: Topic = {
  topic: 'Books, and Literature',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}