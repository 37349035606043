import { Topic } from '.'

const questions = [
  {question: "What's your favorite comfort food that always hits the spot?"},
  {question: "If you could invite any historical figure over for dinner, who would it be and why?"},
  {question: "What's the most memorable meal you've ever had, and where did you have it?"},
  {question: "What's your go-to cookbook or food blog for inspiration?"},
  {question: "If you could only eat one type of cuisine for the rest of your life, what would it be and why?"},
  {question: "What's the most important lesson you've learned while cooking or baking?"},
  {question: "Do you prefer cooking for yourself or for others? Why?"},
  {question: "Have you ever had a culinary disaster that turned out better than expected? Share your story!"},
  {question: "If you could eat any meal with your favorite celebrity chef, who would it be and why?"},
  {question: "What's the most important quality in a recipe, in your opinion? Why?"},
  {question: "Do you prefer sweet or savory dishes? Why?"},
  {question: "Have you ever tried a new food or ingredient that surprised you with its taste or texture? Share your experience!"},
  {question: "What's the most rewarding part of cooking or baking for you?"},
  {question: "Have you ever had a food-related memory that brings back strong emotions or nostalgia? Share your story!"},
  {question: "If you could only eat one type of cuisine for breakfast, lunch, and dinner, what would it be and why?"},
  {question: "Have you ever attempted a new recipe only to find out it was a total disaster? Share your funniest kitchen mishap!"},
  {question: "Do you prefer cooking from scratch or using pre-made ingredients? Why?"},
  {question: "Have you ever tried a new ingredient or dish that surprised you with its taste or texture? Share your experience!"},
  {question: "If you could have any superpower related to food, what would it be and why?"},
  {question: "What's the most challenging dish you've ever attempted to make, and how did it turn out?"},
  {question: "Have you ever had a food epiphany while traveling or trying a new restaurant? Share your experience!"},
  {question: "If you could only eat one meal a day for the rest of your life, what would it be and why?"},
  {question: "If you could only use five spices or herbs in all your dishes, which ones would you choose and why?"},
  {question: "What's the most creative dish or dessert you've ever made or tried? Share your experience!"},
  {question: "Have you ever had a food-related adventure or exploration that opened your eyes to a new culture or community? Share your story!"}
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Food_and_Cooking: Topic = {
  topic: 'Food, and Cooking',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}