import { ReactNode } from 'react'
import { ReactComponent as Angular } from '../assets/icons/angular.svg'
import { ReactComponent as CSS } from '../assets/icons/css-3.svg'
import { ReactComponent as Django } from '../assets/icons/dj.svg'
import { ReactComponent as Gatsby } from '../assets/icons/gatsby.svg'
import { ReactComponent as JavaScript } from '../assets/icons/javascript.svg'
import { ReactComponent as Kotlin } from '../assets/icons/kotlin.svg'
import { ReactComponent as Laravel } from '../assets/icons/laravel.svg'
import { ReactComponent as Python } from '../assets/icons/python.svg'
import { ReactComponent as ReactIcon } from '../assets/icons/react.svg'
import { ReactComponent as BulbIcon } from '../assets/icons/bulb.svg'

type QuizTopic = {
  title: string
  disabled?: boolean
}

export const quizTopics: QuizTopic[] = [
  {
    title: 'Arts, and Culture'
  },
  {
    title: 'Books, and Literature',
  },
  {
    title: 'Music, Films, and TV',
  },
  {
    title: 'STEM, and Astronomy',
  },
  {
    title: 'DIY, Electronics, and Robotics',
  },
  {
    title: 'Food, and Cooking',
  },
  {
    title: 'Travel, and Geography',
  },
  {
    title: 'Hobbies, and crafts',
  },
  {
    title: 'Fashion, and Beauty',
  },
  {
    title: 'Pets',
  },
  {
    title: 'Sports, and Fitness',
  },
  {
    title: 'Nature, and Environment',
  },
  {
    title: 'Education, and Learning',
  },
  {
    title: 'Volunteering',
  },
  {
    title: 'History, and Archaelogy',
  },
  {
    title: 'Languages, and Linguistics',
  },
  {
    title: 'Careers, and Professional Development',
  },
  {
    title: 'Philosophy, and ethics',
  }
]
