import { Topic } from '.'

const questions = [
  {question: "Have you ever tried a new sport or fitness class that challenged you in a positive way? What was it, and how did it make you feel?"},
  {question: "If you could have dinner with any athlete or fitness influencer, dead or alive, who would it be and why?"},
  {question: "Have you ever experienced a setback or injury while pursuing sports or fitness? How did you overcome it, and what did you learn from the experience?"},
  {question: "If you could go back in time and give your younger self one piece of advice related to sports or fitness, what would it be?"},
  {question: "What's the most rewarding aspect of participating in sports or fitness for you, and why do you think it's so important?"},
  {question: "What's one thing you're passionate about in the world of sports or fitness, and why is it so important to you?"},
  {question: "If you could design your dream sports or fitness facility, what would it look like and what features would it include?"},
  {question: "What's the most challenging part of sticking to a regular exercise routine or training for a sport, and how do you overcome it?"},
  {question: "What's your favorite piece of sports or fitness gear, and why is it so effective for you?"},
  {question: "What's one thing you wish more people understood about sports or fitness, and why is it so important?"},
  {question: "Have you ever experienced a breakthrough or 'aha' moment during a workout or athletic performance that left a lasting impact on you? What was it, and how did it change your perspective?"},
  {question: "What's your favorite sport or physical activity, and why do you enjoy it?"},
  {question: "What's the most important lesson you've learned through sports or fitness, and how has it impacted your life beyond the playing field?"},
  {question: "What's one thing you wish you knew before starting a new exercise routine or taking up a new sport?"},
  {question: "What's your favorite sports- or fitness-related memory, and why is it so meaningful to you?"},
  {question: "How has sports or fitness helped you develop resilience or perseverance in other areas of your life?"},
  {question: "Have you ever had to overcome self-doubt or negative self-talk while pursuing sports or fitness? How did you do it, and what did you learn from the experience?"},
  {question: "Have you ever had a mentor or role model who inspired you in your sports or fitness journey? Who was it, and what did you learn from them?"},
  {question: "How has sports or fitness helped you develop a growth mindset and embrace challenges as opportunities for growth?"},
  {question: "Have you ever had to balance sports or fitness with other responsibilities, such as work or family obligations? How did you manage it, and what did you learn from the experience?"}
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Sports_and_Fitness: Topic = {
  topic: 'Sports, and Fitness',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}