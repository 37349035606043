import { Topic } from '.'

const questions = [
  {question: "What is the most challenging ethical dilemma you've faced, and how did you approach it?"},
  {question: "What is the most fundamental question you've ever asked yourself?"},
  {question: "How do you think your personal values align with your actions and decisions in life?"},
  {question: "Do you believe that morality can be objective, or is it purely subjective based on individual perspectives?"},
  {question: "What are some common misconceptions about philosophy and ethics that you encounter in your daily life?"},
  {question: "Can you describe a time when you had to make a difficult decision based on your personal values and principles?"},
  {question: "How do you think we can foster empathy and understanding among individuals with differing perspectives and beliefs?"},
  {question: "What is the relationship between ethics and happiness, in your opinion?"},
  {question: "How do you approach critical thinking and evaluating information in your daily life?"},
  {question: "What is the most important lesson you've learned from studying philosophy and ethics?"},
  {question: "How do you think we can promote critical thinking and philosophical inquiry in our education system?"},
  {question: "What is the role of intuition in ethical decision-making, in your view?"},
  {question: "How do you think technology will shape our understanding of ethics and morality in the future?"},
  {question: "Is there a particular philosophical concept or idea that resonates with you the most, and why?"},
  {question: "How do you think we can balance individual freedom with social responsibility and accountability?"},
  {question: "What are some practical applications of philosophical and ethical concepts in your everyday life?"},
  {question: "Is there a particular historical figure or philosopher whose ideas have had a significant impact on your own thinking?"},
  {question: "Can you discuss a time when you had to navigate a conflicting situation between your personal beliefs and societal expectations?"},
  {question: "Can you describe a time when you had to reconcile a conflicting value or principle with another important aspect of your life?"},
  {question: "How do you think we can cultivate a deeper understanding of ourselves and others through philosophical inquiry and self-reflection?"}  
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Philosophy_and_ethics: Topic = {
  topic: 'Philosophy, and ethics',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}