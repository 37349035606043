import { Topic } from '.'

const questions = [
  {question: "What's your favorite type of pet, and why?"},
  {question: "Have you ever had a pet that was a bit of a challenge to care for? How did you handle it?"},
  {question: "If you could have any animal as a pet, what would it be and why?"},
  {question: "Do you prefer cats or dogs, and why?"},
  {question: "What's the most memorable experience you've had with a pet?"},
  {question: "How do you think pets can positively impact our lives and well-being?"},
  {question: "Do you have any funny or embarrassing stories about your pet?"},
  {question: "What do you think is the biggest misconception people have about pets and animals?"},
  {question: "How has your relationship with your pet changed over time?"},
  {question: "Do you prefer to adopt pets from shelters or buy them from breeders? Why?"},
  {question: "How do you think technology has impacted the way we care for our pets?"},
  {question: "What's the most important thing to consider when choosing a veterinarian for your pet?"},
  {question: "Have you ever participated in a pet-related hobby or activity, such as agility training or showing? What was that like for you?"},
  {question: "Do you have any favorite pet-friendly destinations or activities? Why do you enjoy them?"},
  {question: "What's the most interesting thing you've learned about an animal recently?"},
  {question: "Do you prefer to have one pet at a time or multiple pets simultaneously? Why?"},
  {question: "What's the most challenging part of caring for a pet, in your opinion?"},
  {question: "Do you have any favorite pet-related books, movies, or TV shows? What are they and why do you enjoy them?"},
  {question: "How has your experience with pets and animals influenced your personal growth and development?"},
  {question: "What's the most rewarding aspect of owning a pet, in your opinion?"},
  {question: "Do you have any tips for keeping pets safe and secure in the home?"},
  {question: "Have you ever had to deal with a pet that was experiencing separation anxiety? How did you help them adjust?"},
  {question: "How has your experience with pets and animals influenced your relationships with other people?"},
  {question: "What's the most interesting thing you've learned about the history of pets and animals?"},
  {question: "Do you have any tips for keeping pets happy and engaged during the winter months?"},
  {question: "What's the most rewarding part of owning a pet, in your opinion?"},
  {question: "Have you ever had to work with an animal that had a special talent or skill, such as agility training or obedience training? What was that like for you?"},
  {question: "How has your experience with pets and animals influenced your worldview and perspective on life?"},
  {question: "What's the most challenging part of caring for a pet, in your opinion?"},
  {question: "Have you ever had to say goodbye to a beloved pet, and how did you cope with the loss?"},
  {question: "Do you have any tips for keeping pets safe during the summer months?"},
  {question: "What's the most rewarding aspect of owning a pet, in your opinion?"},
  {question: "Do you have any favorite pet-related books, movies, or TV shows? What are they and why do you enjoy them?"},
  {question: "How has your experience with pets and animals influenced your personal growth and development?"},
  {question: "What's the most important thing to consider when choosing a pet-friendly vacation destination?"},
  {question: "Have you ever had to say goodbye to a beloved pet, and how did you cope with the loss?"},
  {question: "Do you have any tips for keeping pets safe during the holiday season?"},
  {question: "What's the most interesting thing you've learned about an animal recently?"},
  {question: "What's the most rewarding part of owning a pet, in your opinion?"},
  {question: "Do you have any favorite pet-related memories or stories? Why are they meaningful to you?"},
  {question: "How has your experience with pets and animals influenced your relationships with other people?"},
  {question: "What's the most challenging part of caring for a pet, in your opinion?"},
  {question: "Have you ever had to say goodbye to a beloved pet, and how did you cope with the loss?"},
  {question: "What's the most interesting thing you've learned about an animal recently?"},
  {question: "What's the most important thing to consider when choosing a pet-friendly home or apartment?"},
  {question: "Do you have any favorite pet-related products or gadgets, such as food puzzles or interactive toys? Why do you like them?"},
  {question: "How has your experience with pets and animals influenced your view of the world and your place in it?"},
  {question: "Have you ever had to deal with a pet that was experiencing separation anxiety? How did you help them adjust?"},
  {question: "What's the most rewarding aspect of owning a pet, in your opinion?"},
  {question: "Do you have any tips for keeping pets happy and engaged during the winter months?"},
  {question: "What's the most challenging part of caring for a pet, in your opinion?"},
  {question: "What's the most interesting thing you've learned about an animal recently?"},
  {question: "What's the most rewarding part of owning a pet, in your opinion?"},
  {question: "Do you have any favorite pet-related memories or stories? Why are they meaningful to you?"},
  {question: "How has your experience with pets and animals influenced your perspective on life and the world around you?"},
  {question: "What's the most important thing to consider when choosing a pet-friendly park or outdoor space?"},
  {question: "Have you ever had to say goodbye to a beloved pet, and how did you cope with the loss?"},
  {question: "What's the most interesting thing you've learned about an animal recently?"},
  {question: "What's the most rewarding aspect of owning a pet, in your opinion?"},
  {question: "Do you have any tips for keeping pets happy and engaged during the spring months?"},
  {question: "Have you ever had to deal with a pet that was experiencing joint pain or mobility issues? How did you help them feel more comfortable?"},
  {question: "What's the most challenging part of caring for a pet, in your opinion?"},
  {question: "Have you ever taken your pet on a special outing or adventure? Where did you go and how did it go?"},
  {question: "What's the most important thing to consider when choosing a pet?"},
  {question: "Have you ever volunteered at an animal shelter or rescue organization? If so, what was that experience like for you?"},
  {question: "Have you ever had to make a difficult decision about your pet's care? What was the situation and how did you handle it?"},
  {question: "Do you have any tips for keeping pets healthy and happy?"},
  {question: "Have you ever had to deal with a pet emergency, such as a broken leg or a severe illness? How did you handle it?"},
  {question: "How has your experience with pets and animals influenced your views on conservation and animal welfare?"},
  {question: "Have you ever had to say goodbye to a beloved pet? How did you cope with the loss?"},
  {question: "Have you ever had to deal with a pet that was afraid of something, such as loud noises or new environments? How did you help them overcome their fear?"},
  {question: "Have you ever considered becoming a professional animal trainer or working with animals in some other capacity? Why or why not?"},
  {question: "Have you ever had to deal with a pet that was prone to destructive behavior, such as chewing or digging? How did you address the issue?"},
  {question: "Have you ever had to work with an animal that had a special need or requirement, such as a physical disability or a specific dietary restriction? How did you accommodate their needs?"},
  {question: "What's the most important thing to consider when choosing a pet-sitter or dog-walker?"},
  {question: "Do you have any favorite pet-related products or gadgets, such as food puzzles or interactive toys? Why do you like them?"},
  {question: "Have you ever had to deal with a pet that was experiencing health issues, such as arthritis or allergies? How did you help them feel more comfortable?"},
  {question: "Have you ever had to deal with a pet that was experiencing behavioral issues, such as barking or meowing excessively? How did you address the problem?"},
  {question: "Do you have any favorite pet-related memories or stories? Why are they meaningful to you?"},
  {question: "Have you ever had to deal with a pet that was experiencing a phobia or fear, such as a fear of loud noises or strangers? How did you help them overcome their anxiety?"},
  {question: "What's the most interesting thing you've learned about an animal recently?"},
  {question: "Have you ever had to deal with a pet that was experiencing joint pain or mobility issues? How did you help them feel more comfortable?"},
  {question: "Have you ever had to work with an animal that had a special need or requirement, such as a physical disability or a specific dietary restriction? How did you accommodate their needs?"},
  {question: "Have you ever had to deal with a pet that was experiencing health issues, such as allergies or infections? How did you help them feel better?"},
  {question: "Have you ever had to deal with a pet that was experiencing behavioral issues, such as aggression or anxiety? How did you address the problem?"},
  {question: "Have you ever had to work with an animal that had a special talent or skill, such as agility training or obedience training? What was that like for you?"},
  {question: "Have you ever had to deal with a pet that was experiencing joint pain or mobility issues? How did you help them feel more comfortable?"},
  {question: "Do you have any tips for keeping pets safe during extreme weather conditions, such as heatwaves or blizzards?"},
  {question: "Have you ever had to deal with a pet that was experiencing health issues, such as allergies or infections? How did you help them feel better?"},
  {question: "Have you ever had to work with an animal that had a special need or requirement, such as a physical disability or a specific dietary restriction? How did you accommodate their needs?"},
  {question: "Have you ever had to work with an animal that had a special talent or skill, such as agility training or obedience training? What was that like for you?"},
  {question: "Have you ever had to deal with a pet that was experiencing behavioral issues, such as barking or meowing excessively? How did you address the problem?"},
  {question: "Have you ever had to say goodbye to a beloved pet, and how did you cope with the loss?"},
  {question: "Do you have any tips for keeping pets safe during the summer months?"},
  {question: "Have you ever had to deal with a pet that was experiencing joint pain or mobility issues? How did you help them feel more comfortable?"},
  {question: "Have you ever had to work with an animal that had a special need or requirement, such as a physical disability or a specific dietary restriction? How did you accommodate their needs?"},
  {question: "Have you ever had to deal with a pet that was experiencing health issues, such as allergies or infections? How did you help them feel better?"},
  {question: "Do you have any tips for keeping pets safe during holiday celebrations and gatherings?"},
  {question: "Have you ever had to deal with a pet that was experiencing behavioral issues, such as aggression or anxiety? How did you address the problem?"},
  {question: "Have you ever had to work with an animal that had a special talent or skill, such as agility training or obedience training? What was that like for you?"}  
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Pets: Topic = {
  topic: 'Pets',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}