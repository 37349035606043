import { Topic } from '.'

const questions = [
  {question: "What's the most interesting thing you learned in school today?"},
  {question: "What's your favorite subject in school and why?"},
  {question: "Do you prefer studying alone or in a group? Why?"},
  {question: "If you could go back in time, what advice would you give your younger self about education and learning?"},
  {question: "Do you enjoy group projects or prefer working individually on assignments? Why?"},
  {question: "Have you ever had to balance schoolwork with other responsibilities, like a part-time job or family obligations? How did you manage it?"},
  {question: "If you could design your dream course or program, what would it be about and why?"},
  {question: "What's the most important thing you've learned in school that you think everyone should know? Why?"},
  {question: "Do you prefer a more structured or flexible approach to learning? Why?"},
  {question: "What's the most rewarding part of learning for you? Is it understanding new concepts, mastering new skills, or something else entirely?"},
  {question: "What's the biggest misconception people have about education and learning? How do you think we can address this misconception and promote a more accurate understanding of education?"},
  {question: "What's the most important thing you've learned outside of school that you think everyone should know? Why?"},
  {question: "Do you prefer a more traditional or modern approach to education? Why?"},
  {question: "What's the most challenging part of learning for you? How do you overcome it?"},
  {question: "Have you ever had to work with someone who had a different learning style or perspective than you? How did you adapt and what did you learn from the experience?"},
  {question: "What's the most valuable thing you've learned in school so far? Why is it important to you?"},
  {question: "What's the most interesting thing you've learned recently? How did you learn it and why is it interesting to you?"},
  {question: "Have you ever had a teacher who inspired you to learn more? Who was it and what did they do that inspired you?"},
  {question: "What's the most interesting thing you've learned outside of school? How did you learn it?"},
  {question: "How do you prefer to learn new things - through reading, listening, or hands-on experience?"},
  {question: "What's the biggest challenge you face when it comes to learning new things? How do you overcome it?"},
  {question: "Have you ever had to overcome any obstacles to pursue your educational goals? If so, how did you do it?"},
  {question: "What's the most important skill or knowledge you've gained from your education so far? How has it helped you in your life?"},
  {question: "Have you ever had to overcome any learning disabilities or challenges? How did you address them and what strategies worked best for you?"},
  {question: "Have you ever had to make a difficult decision about your education, like choosing between two passions or pursuing a different career path? How did you decide and what was the outcome?"},
  {question: "Have you ever had to learn something that challenged your beliefs or perspectives? How did you approach it and what did you learn from the experience?"},
  {question: "Have you ever had to balance your educational goals with personal interests or passions? How did you manage it and what did you learn from the experience?"},
  {question: "Have you ever had to navigate different teaching styles or learning environments? How did you adapt and what did you learn from the experience?"},
  {question: "Do you prefer to learn through hands-on experiences or more theoretical approaches? Why?"},
  {question: "Have you ever had to balance your education with other aspects of your life, like family or social obligations? How did you manage it and what did you learn from the experience?"}
  
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Education_and_Learning: Topic = {
  topic: 'Education, and Learning',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}