import { Topic } from '.'

const questions = [
  {question: "What sparked your interest in history and archaeology?"},
  {question: "Have you ever visited a historical site or museum that left a lasting impression on you? Which one and why?"},
  {question: "How do you think history and archaeology can help us better understand our present and shape our future?"},
  {question: "What do you think is the most challenging part of preserving historical sites and artifacts for future generations?"},
  {question: "How do you think technology and innovation can aid in the preservation and study of historical sites and artifacts?"},
  {question: "What is the most intriguing unsolved mystery or enigma in the field of history and archaeology that you're aware of?"},
  {question: "Have you ever participated in a historical reenactment or experiment? Share your experience and what you learned from it."},
  {question: "If you could time-travel to any era to witness a historical event firsthand, which one would you choose and why?"},
  {question: "Have you ever come across an artifact or text that challenged your preconceptions about history or archaeology? What was it, and how did it change your perspective?"},
  {question: "How do you think the study of history and archaeology can promote cross-cultural understanding and tolerance?"},
  {question: "If you could speak with a historical figure, who would it be and why? What questions would you ask them?"},
  {question: "If you could travel back in time to any era, which one would you choose and why?"},
  {question: "What do you think is the most significant discovery or find in the field of archaeology in recent years?"},
  {question: "Imagine you are an archaeologist on a mission to uncover a lost civilization. Where would you look for clues, and what might you discover?"},
  {question: "How has your understanding of history and archaeology evolved over time? What new insights have you gained recently?"},
  {question: "How do you think history and archaeology can inform our understanding of contemporary social and political issues?"},
  {question: "What is the most significant difference you've noticed between how history and archaeology are perceived and studied in different cultures or regions?"},
  {question: "Have you ever encountered a mysterious or controversial artifact or event in your studies of history and archaeology? What was it, and what did it make you think about?"},
  {question: "Have you ever encountered a historical figure or event that you felt deserved more attention or recognition? Why is that, and what would you like to know more about?"},
  {question: "Have you ever come across a historical artifact or relic that fascinated you? What was it, and what did it reveal about the past?"}  
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const History_and_Archaelogy: Topic = {
  topic: 'History, and Archaelogy',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}