import { Topic } from '.'

const questions = [
  {question: "What's your favorite hobby or craft? Why do you enjoy it so much?"},
  {question: "Do you prefer solo or group activities when it comes to your hobbies and crafts? Why?"},
  {question: "Do you have any favorite tools or materials related to your hobby or craft? Why do you like them so much?"},
  {question: "How has your chosen hobby or craft impacted your life in any way? Has it taught you any valuable skills, or does it simply provide a creative outlet?"},
  {question: "How do you stay inspired and motivated in your chosen hobby or craft? Do you have any go-to resources for ideas and inspiration?"},
  {question: "What's the most satisfying part of your chosen hobby or craft? Why do you enjoy it so much?"},
  {question: "Do you have any favorite hobby or craft bloggers, YouTubers, or influencers that you follow for inspiration and ideas? Who are they, and what do you enjoy about their content?"},
  {question: "How do you balance your time between your hobby or craft and other aspects of your life? Do you prioritize one over the other, or do you try to make time for both equally?"},
  {question: "Do you have any favorite books, podcasts, or other resources related to your hobby or craft that you would recommend to others? Why do you like them so much?"},
  {question: "How has your chosen hobby or craft helped you grow as a person? Have you learned any valuable life skills or lessons through it?"},
  {question: "What's the most rewarding part of sharing your hobby or craft with others? Do you enjoy teaching others, or do you prefer to work on projects independently?"},
  {question: "Do you have any favorite hobby or craft-related podcasts, videos, or articles that you enjoy listening to or reading when looking for inspiration or ideas? What are they, and why do you like them so much?"},
  {question: "What's the most creative part of your chosen hobby or craft? Do you enjoy brainstorming new ideas or experimenting with different techniques?"},
  {question: "How did you first get into your current hobby or craft? Was it a childhood passion or something you discovered later in life?"},
  {question: "What's the most challenging part of your chosen hobby or craft? How do you overcome those challenges?"},
  {question: "Have you ever tried a new hobby or craft that completely surprised you with how much you enjoyed it? What was it, and why did you enjoy it so much?"},
  {question: "Have you ever collaborated with others on a project related to your hobby or craft? What was that experience like, and what did you learn from it?"},
  {question: "Have you ever had to troubleshoot a problem or fix a mistake while working on a project related to your hobby or craft? How did you resolve the issue, and what did you learn from the experience?"},
  {question: "Have you ever participated in a craft fair or other event related to your hobby or craft? What was that experience like, and did you learn anything from it?"},
  {question: "Have you ever tried a new technique or skill related to your hobby or craft that completely changed the way you approach it? What was it, and how did it impact your experience?"},
  {question: "Have you ever had to set aside your hobby or craft for a period of time due to other commitments or life events? How did you feel about that experience, and what did you learn from it?"},
  {question: "Have you ever incorporated your hobby or craft into other aspects of your life, such as using it as a form of stress relief or incorporating it into your work? How did that experience go, and what did you learn from it?"},
  {question: "How has your chosen hobby or craft impacted your relationships with others? Have you formed any meaningful connections with other enthusiasts or crafters?"},
  {question: "Have you ever tried a new hobby or craft that was completely unrelated to anything you had done before? How did you find the experience, and what did you learn from it?"},
  {question: "Have you ever had to repair or restore a project related to your hobby or craft that was damaged or worn out? What was the process like, and what did you learn from it?"}
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Hobbies_and_crafts: Topic = {
  topic: 'Hobbies, and crafts',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}