
import { Arts_and_Culture } from './Arts_and_Culture'
import { Books_and_Literature } from './Books_and_Literature'
import { Music_Films_and_TV } from './Music_Films_and_TV'
import { STEM_and_Astronomy } from './STEM_and_Astronomy'
import { DIY_Electronics_and_Robotics } from './DIY_Electronics_and_Robotics'
import { Food_and_Cooking } from './Food_and_Cooking'
import { Travel_and_Geography } from './Travel_and_Geography'
import { Hobbies_and_crafts } from './Hobbies_and_crafts'
import { Fashion_and_Beauty } from './Fashion_and_Beauty'
import { Pets } from './Pets'
import { Sports_and_Fitness } from './Sports_and_Fitness'
import { Nature_and_Environment } from './Nature_and_Environment'
import { Education_and_Learning } from './Education_and_Learning'
import { Volunteering } from './Volunteering'
import { History_and_Archaelogy } from './History_and_Archaelogy'
import { Languages_and_Linguistics } from './Languages_and_Linguistics'
import { Careers_and_Professional_Development } from './Careers_and_Professional_Development'
import { Philosophy_and_ethics } from './Philosophy_and_ethics'


export type Question = {
  question: string
}

export type Topic = {
  topic: string
  totalQuestions: number
  questions: Question[]
}

export const quiz: Record<string, Topic> = {
  'Arts, and Culture' : Arts_and_Culture,
  'Books, and Literature' : Books_and_Literature,
  'Music, Films, and TV' : Music_Films_and_TV,
  'STEM, and Astronomy' : STEM_and_Astronomy,
  'DIY, Electronics, and Robotics' : DIY_Electronics_and_Robotics,
  'Food, and Cooking' : Food_and_Cooking,
  'Travel, and Geography' : Travel_and_Geography,
  'Hobbies, and crafts' : Hobbies_and_crafts,
  'Fashion, and Beauty' : Fashion_and_Beauty,
  'Pets' : Pets,
  'Sports, and Fitness' : Sports_and_Fitness,
  'Nature, and Environment' : Nature_and_Environment,
  'Education, and Learning' : Education_and_Learning,
  'Volunteering' : Volunteering,
  'History, and Archaelogy' : History_and_Archaelogy,
  'Languages, and Linguistics' : Languages_and_Linguistics,
  'Careers, and Professional Development' : Careers_and_Professional_Development,
  'Philosophy, and ethics' : Philosophy_and_ethics,
}
