import { Topic } from '.'

const questions = [
    
{question: "What's the most interesting aspect of linguistics that you've come across?"},
{question: "What is your favorite language to learn and why?"},
{question: "Have you ever tried learning a new language? If so, which one and how was the experience?"},
{question: "Which language do you think is the most beautiful and why?"},
{question: "Have you ever studied a dead language? If so, which one and what was that experience like?"},
{question: "How has technology influenced language learning and communication in your opinion?"},
{question: "How do you think languages can bring people together across cultures and borders?"},
{question: "What's the most interesting linguistic phenomenon you've come across in your native language or another language?"},
{question: "How do you think language learning can be made more accessible and inclusive for everyone?"},
{question: "What's the most important lesson you've learned from studying linguistics or learning a new language?"},
{question: "How do you think language reflects the culture and values of a society?"},
{question: "What's the most rewarding aspect of language learning in your opinion?"},
{question: "Have you ever tried to learn a language through immersion or total physical response methods? If so, how was that experience?"},
{question: "What's the most challenging part of language learning for you?"},
{question: "Have you ever had any inspiring language-related experiences or encounters with native speakers? Can you share some examples?"},
{question: "How do you think technology can be used to support and enhance language learning?"},
{question: "What's the most important quality of a good language teacher in your opinion?"},
{question: "Have you ever encountered any interesting linguistic variations within your native language or another language? Can you share some examples?"},
{question: "How do you think learning a new language has helped you in your daily life?"},
{question: "Have you ever encountered any challenges while communicating with someone who speaks a different language? How did you overcome those challenges?"},
{question: "Do you have any favorite linguistic expressions or idioms in your native language? Can you share some examples?"},
{question: "What's the most memorable thing someone has ever said to you in a different language?"},
{question: "Have you ever tried to learn a sign language or any other non-verbal communication method? If so, how was that experience?"},
{question: "Have you ever had any funny misunderstandings or miscommunications due to language barriers? Can you share some examples?"},
{question: "Have you ever encountered any unexpected similarities between languages? Can you share some examples?"},
{question: "How can we preserve endangered languages and promote linguistic diversity in our communities?"},
{question: "How do you think languages can be used as a tool for social change and progress?"},
{question: "What's the most fascinating linguistic phenomenon you've come across in your studies or travels?"},
{question: "Have you ever tried to learn a language through online resources or apps? If so, which ones and how was that experience?"},
{question: "How do you think languages can be used to bridge cultural divides and promote understanding between communities?"}

]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Languages_and_Linguistics: Topic = {
  topic: 'Languages, and Linguistics',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}