import { Topic } from '.'

const questions = [
  {question: "What inspired you to pursue your current career path?"},
  {question: "How did you discover your passion for your chosen profession?"},
  {question: "What skills have you found most valuable in your professional journey so far?"},
  {question: "How do you stay up-to-date with the latest developments and trends in your industry?"},
  {question: "Can you share a time when you had to overcome an obstacle or challenge in your career?"},
  {question: "How do you balance work and personal life, if applicable?"},
  {question: "What are some common misconceptions about your field, and how do you address them?"},
  {question: "How do you approach networking and building professional connections?"},
  {question: "What are some of the most important qualities for success in your profession?"},
  {question: "Can you tell us about a particularly meaningful mentorship or mentoring experience?"},
  {question: "How do you handle stress and pressure in the workplace?"},
  {question: "Can you discuss a time when you had to make a difficult decision in your career?"},
  {question: "What advice would you offer to your younger self, looking back on your professional journey so far?"},
  {question: "How do you approach goal-setting and planning for the future in your profession?"},
  {question: "Can you discuss a time when you had to adapt to a new work environment or culture?"},
  {question: "How do you handle feedback and constructive criticism in your professional life?"},
  {question: "What are some of the most rewarding aspects of your current job?"},
  {question: "How do you handle conflict resolution and problem-solving in your professional life?"},
  {question: "Can you tell us about a particularly memorable moment or achievement in your career?"},
  {question: "How do you approach networking and building connections within your industry?"},
  {question: "Can you discuss a time when you had to think creatively to solve a problem at work?"},
  {question: "What are some of the most important skills or tools for success in your profession?"},
  {question: "How do you approach goal-setting and planning for the future in your personal life as well as your professional one?"},
  {question: "Can you discuss a time when you had to navigate a challenging work environment or culture?"},
  {question: "How do you handle feedback and constructive criticism in your personal life as well as your professional one?"},
  {question: "Can you tell us about a particularly innovative solution or project you're currently working on?"},
  {question: "What are some of the most rewarding aspects of your current job, and how do you cultivate those experiences?"},
  {question: "How do you handle conflict resolution and problem-solving in both your personal and professional life?"},
  {question: "What advice would you offer to someone looking to transition into your field, taking into account both the professional and personal aspects of the transition?"},
  {question: "How do you handle setbacks and failures in both your personal and professional life?"},
  {question: "What are some of the most important skills or tools for success in both your personal and professional life?"},
  {question: "How do you approach goal-setting and planning for the future in both your personal and professional life?"},
  {question: "Can you discuss a time when you had to navigate a challenging work environment or culture while also managing your personal life?"},
  {question: "How do you handle feedback and constructive criticism in both your personal and professional life?"},
  {question: "Can you tell us about a particularly innovative solution or project you're currently working on that combines both your personal and professional interests?"},
  {question: "What are some of the most rewarding aspects of your current job, and how do you cultivate those experiences both personally and professionally?"},
  {question: "How do you handle conflict resolution and problem-solving in both your personal and professional life, taking into account the unique challenges and opportunities of each context?"},
  {question: "What advice would you offer to someone looking to transition into your field, taking into account both the professional and personal aspects of the transition?"},
  {question: "How do you handle setbacks and failures in both your personal and professional life, and what strategies do you use to bounce back from these experiences?"},
  {question: "Can you tell us about a particularly fulfilling experience or achievement in both your personal and professional life, and what made it so meaningful to you?"},
  {question: "Can you discuss a time when you had to navigate a challenging work environment or culture while also managing your personal life and goals?"},
  {question: "How do you handle feedback and constructive criticism in both your personal and professional life, taking into account the unique challenges and opportunities of each context?"},
  {question: "Can you tell us about a particularly innovative solution or project you're currently working on that combines both your personal and professional interests and passions?"},
  {question: "What are some of the most rewarding aspects of your current job, and how do you cultivate those experiences both personally and professionally?"},
  {question: "How do you handle conflict resolution and problem-solving in both your personal and professional life, taking into account the unique challenges and opportunities of each context?"},
  {question: "What advice would you offer to someone looking to transition into your field, taking into account both the professional and personal aspects of the transition?"},
  {question: "How do you handle setbacks and failures in both your personal and professional life, and what strategies do you use to bounce back from these experiences?"},
  {question: "Can you tell us about a particularly fulfilling experience or achievement in both your personal and professional life, and what made it so meaningful to you?"},
  {question: "How do you approach goal-setting and planning for the future in both your personal and professional life, taking into account the unique challenges and opportunities of each context?"},
  {question: "Can you discuss a time when you had to navigate a challenging work environment or culture while also managing your personal life and goals?"},
  {question: "What are some of the biggest challenges facing your industry or profession right now, and how are you addressing them both personally and professionally?"},
  {question: "Can you tell us about a particularly innovative solution or project you're currently working on that combines both your personal and professional interests and passions?"},
  {question: "What are some of the most rewarding aspects of your current job, and how do you cultivate those experiences both personally and professionally?"},
  {question: "How do you handle conflict resolution and problem-solving in both your personal and professional life, taking into account the unique challenges and opportunities of each context?"},
  {question: "What advice would you offer to someone looking to transition into your field, taking into account both the professional and personal aspects of the transition?"},
  {question: "How do you handle setbacks and failures in both your personal and professional life, and what strategies do you use to bounce back from these experiences?"},
  {question: "Can you tell us about a particularly fulfilling experience or achievement in both your personal and professional life, and what made it so meaningful to you?"},
  {question: "Can you tell us about a particularly innovative solution or project you've worked on?"},
  {question: "Can you tell us about a particularly fulfilling project or experience in your field?"},
  {question: "What advice would you offer to someone just starting out in your field?"},
  {question: "What motivates you to continue learning and growing in your profession?"},
  {question: "Can you discuss a time when you had to work with someone from a different background or discipline?"},
  {question: "How has your career path influenced your personal growth and development?"},
  {question: "What are some of the most rewarding aspects of your job?"},
  {question: "How do you prioritize self-care and maintain a healthy work-life balance?"},
  {question: "Can you tell us about a particularly memorable colleague or collaborator?"},
  {question: "What are some of the most important lessons you've learned throughout your career?"},
  {question: "What are some of the biggest challenges facing your industry or profession right now?"},
  {question: "How do you approach teamwork and collaboration in your profession?"},
  {question: "Can you discuss a time when you had to navigate a complex interpersonal situation at work?"},
  {question: "What are some of the most important qualities for success in your industry?"},
  {question: "What advice would you offer to someone looking to transition into your field?"},
  {question: "How do you handle setbacks and failures in your professional life?"},
  {question: "Can you tell us about a particularly fulfilling mentorship or coaching experience?"},
  {question: "What are some of the most important lessons you've learned throughout your career so far?"},
  {question: "What are some of the biggest challenges facing your industry or profession right now, and how are you addressing them?"},
  {question: "How do you approach teamwork and collaboration in your personal life as well as your professional one?"},
  {question: "Can you discuss a time when you had to navigate a complex interpersonal situation both in your personal and professional life?"},
  {question: "Can you discuss a time when you had to think creatively to solve a problem both in your personal and professional life?"},
  {question: "Can you tell us about a particularly fulfilling experience or achievement in both your personal and professional life?"},
  {question: "What are some of the most important lessons you've learned throughout your career and personal growth journey so far?"},
  {question: "What are some of the biggest challenges facing your industry or profession right now, and how are you addressing them both personally and professionally?"},
  {question: "How do you approach teamwork and collaboration in both your personal and professional life, taking into account the unique challenges and opportunities of each context?"},
  {question: "Can you discuss a time when you had to navigate a complex interpersonal situation both in your personal and professional life, and how you approached resolving the issue?"},
  {question: "Can you discuss a time when you had to think creatively to solve a problem both in your personal and professional life, and how you approached the challenge?"},
  {question: "What are some of the most important skills or tools for success in both your personal and professional life, and how have you developed and honed these skills over time?"},
  {question: "How do you approach goal-setting and planning for the future in both your personal and professional life, taking into account the unique challenges and opportunities of each context?"},
  {question: "What are some of the most important lessons you've learned throughout your career and personal growth journey so far, and how have these experiences shaped your approach to your current job and personal life?"},
  {question: "What are some of the biggest challenges facing your industry or profession right now, and how are you addressing them both personally and professionally?"},
  {question: "How do you approach teamwork and collaboration in both your personal and professional life, taking into account the unique challenges and opportunities of each context?"},
  {question: "Can you discuss a time when you had to navigate a complex interpersonal situation both in your personal and professional life, and how you approached resolving the issue?"},
  {question: "Can you discuss a time when you had to think creatively to solve a problem both in your personal and professional life, and how you approached the challenge?"},
  {question: "What are some of the most important skills or tools for success in both your personal and professional life, and how have you developed and honed these skills over time?"},
  {question: "What are some of the most important lessons you've learned throughout your career and personal growth journey so far, and how have these experiences shaped your approach to your current job and personal life?"},
  {question: "How do you handle feedback and constructive criticism in both your personal and professional life, taking into account the unique challenges and opportunities of each context?"},
  {question: "How do you approach teamwork and collaboration in both your personal and professional life, taking into account the unique challenges and opportunities of each context?"},
  {question: "Can you discuss a time when you had to navigate a complex interpersonal situation both in your personal and professional life, and how you approached resolving the issue?"},
  {question: "Can you discuss a time when you had to think creatively to solve a problem both in your personal and professional life, and how you approached the challenge?"},
  {question: "What are some of the most important skills or tools for success in both your personal and professional life, and how have you developed and honed these skills over time?"},
  {question: "How do you approach goal-setting and planning for the future in both your personal and professional life, taking into account the unique challenges and opportunities of each context?"}
  
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Careers_and_Professional_Development: Topic = {
  topic: 'Careers, and Professional Development',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}