
import { Topic } from '.'


const questions = [
  {question: "What's your go-to karaoke song? Why do you love it?"},
{question: "Have you ever been to a concert or festival? If so, which one was your favorite?"},
{question: "Do you prefer watching movies at home or in theaters? Why?"},
{question: "What's your favorite TV show currently airing? Why do you like it?"},
{question: "Do you have a favorite film score or soundtrack? Which one and why do you like it?"},
{question: "Have you ever attended a filming or recording of a TV show or movie? What was it like?"},
{question: "Do you have a favorite music artist or band that you discovered through a TV show or movie? Which one and why do you like them?"},
{question: "What's the best documentary you've seen recently? Why did you enjoy it?"},
{question: "What's your favorite music video of all time? Why do you like it?"},
{question: "Have you ever tried to create your own film or music project? If so, what was it and how did it go?"},
{question: "Have you ever attended a live taping of a TV show? Which one and what was it like?"},
{question: "What's your favorite song from a musical or soundtrack? Why do you love it?"},
{question: "What's the best movie you've seen recently? Why did you enjoy it?"},
{question: "Have you ever binge-watched an entire season of a show in one sitting? If so, which one?"},
{question: "What's the most memorable scene or moment from a movie or TV show that you've seen recently? Why did it stand out to you?"},
{question: "What's the most iconic song or scene from a movie or TV show that you love? Why is it so memorable to you?"},
{question: "Have you ever attended a film festival? If so, which one was your favorite experience?"},
{question: "Do you prefer watching movies or TV shows with a group of people or by yourself? Why?"},
{question: "What's the most underrated movie or TV show that you think more people should know about? Why do you think it's worth checking out?"},
{question: "Do you have a favorite TV theme song that you always look forward to hearing? Which one and why do you like it?"}
]




function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
  return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Music_Films_and_TV: Topic = {
  topic: 'Music, Films, and TV',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}