import { Topic } from '.'

const questions = [
  {question: "Have you ever participated in any environmental conservation efforts?"},
  {question: "What's your favorite outdoor activity, and why do you enjoy it?"},
  {question: "How do you think humans can better coexist with the natural world?"},
  {question: "What's the most beautiful natural sight you've ever seen, and where was it located?"},
  {question: "Have you ever had a meaningful encounter with a wild animal or plant?"},
  {question: "What's your favorite season, and why do you enjoy it?"},
  {question: "Have you ever noticed how certain natural environments can evoke emotions or memories?"},
  {question: "How has your relationship with nature changed over the years?"},
  {question: "Have you ever noticed how nature can be a source of inspiration for art, music, or literature?"},
  {question: "How has your understanding of the natural world evolved over time?"},
  {question: "What's your favorite type of outdoor adventure, and why do you enjoy it?"},
  {question: "How can we ensure that future generations have access to clean air, water, and soil?"},
  {question: "Have you ever noticed how nature can be a source of comfort during difficult times?"},
  {question: "What's the most beautiful natural phenomenon you've ever witnessed, and where was it located?"},
  {question: "Have you ever spent time in nature, and if so, what was your experience like?"},
  {question: "Do you believe that spending time in nature has any health benefits?"},
  {question: "How do you think we can best protect our environment for future generations?"},
  {question: "Do you prefer spending time in urban or rural areas, and why?"},
  {question: "What's the most important lesson you've learned from spending time in nature?"},
  {question: "What's your favorite type of ecosystem, and why do you find it fascinating?"},
  {question: "How can we balance human development with environmental preservation?"},
  {question: "What's the most challenging aspect of living in a urban environment, and how do you cope with it?"},
  {question: "Have you ever had a transformative experience in nature? If so, what was it like?"},
  {question: "What's the most important thing we can do to protect our planet for the long-term?"},
  {question: "How has your connection with nature influenced your worldview or personal values?"}
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Nature_and_Environment: Topic = {
  topic: 'Nature, and Environment',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}