import { Topic } from '.'

const questions = [
{question: "What inspired you to get involved in volunteering or community service?"},
{question: "Can you share a memorable experience from your volunteer work?"},
{question: "How has volunteering impacted your life, personally and professionally?"},
{question: "Have you ever volunteered for a cause that challenged your personal beliefs or values? How did you navigate that experience?"},
{question: "How do you balance your volunteer work with other responsibilities, such as work and family obligations?"},
{question: "What skills have you gained from your volunteer experiences, and how have they helped you in other areas of your life?"},
{question: "Can you tell us about a particularly rewarding volunteer project you were involved in? What made it so fulfilling?"},
{question: "Have you ever had to overcome any challenges or obstacles while volunteering? How did you handle them?"},
{question: "What are some common misconceptions about volunteering or community service, and how can we address them?"},
{question: "How do you think technology has changed the way we approach volunteering and community service?"},
{question: "Have you ever had to make a difficult decision while volunteering, such as choosing between two competing priorities or values? How did you handle it?"},
{question: "What do you think are the most effective ways to recruit and retain volunteers for a particular cause or organization?"},
{question: "Have you ever had to deal with a difficult or demanding volunteer or staff member while working on a project? How did you handle the situation?"},
{question: "What are some ways that volunteers can engage with their communities beyond just volunteering for a single cause or organization?"},
{question: "Can you tell us about a particularly inspiring or influential volunteer or community leader that you have met or heard about? What made them so impressive?"},
{question: "What do you think are some common traits or characteristics shared by effective volunteers and community leaders?"},
{question: "Can you share a time when you had to work outside of your comfort zone while volunteering, such as taking on a new task or role? How did you handle it?"},
{question: "What are some ways that volunteers can support each other and build a sense of community within their volunteer groups?"},
{question: "Can you tell us about a particularly memorable or impactful volunteer project you were involved in? What made it so significant?"},
{question: "What do you think are the most pressing social issues in your community, and how can volunteers make a difference?"},
{question: "What advice would you give to someone who is new to volunteering or community service?"},
{question: "Have you ever had a meaningful conversation or connection with someone while volunteering? Share that experience."},
{question: "How do you think volunteering can benefit not just the community, but also the individual volunteers themselves?"},
{question: "Can you share a time when you had to work with someone from a different background or culture while volunteering? How did you navigate any differences or challenges that arose?"},
{question: "What are some ways that volunteers can support each other and build a sense of community within their volunteer groups?"},
{question: "Can you tell us about a volunteer opportunity that you wish more people knew about or took advantage of? Why is it so important?"},
{question: "Can you share a time when you had to be flexible and adaptable while volunteering, such as when plans changed or unexpected challenges arose?"},
{question: "Have you ever felt burned out or overwhelmed while volunteering? How did you address those feelings and maintain your commitment to the cause?"},
{question: "Have you ever had to navigate a sensitive or controversial issue while volunteering, such as addressing a social injustice or dealing with conflicting values? How did you approach the situation?"},
{question: "Have you ever felt unsure about how to approach a particular issue or challenge while volunteering? How did you seek guidance or support?"}
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Volunteering: Topic = {
  topic: 'Volunteering',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}