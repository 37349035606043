import { Topic } from '.'

const questions = [
  {                                
    question: "Have you ever attended a play or musical performance? What did you think of the production?"                      
},                               
{                                 
    question: "Have you ever tried a new cuisine or dish from a different culture? What was it, and how did you like it?"        
},                               
{                                
    question: "How do you think art and culture can bring people together across different backgrounds and cultures?"            
},                               
{                                
    question: "How do you think technology has impacted the way we experience and engage with art and culture?"                  
},                               
{                                
    question: "What's your favorite museum or gallery, and why do you enjoy visiting it?"  
},                               
{                                
    question: "Have you ever tried a new form of traditional music or instrument? What was it, and how did you like it?"         
},                               
{                                
    question: "What's your favorite form of traditional dance, and why do you enjoy it?"   
},                               
{                                
    question: "Have you ever attended a poetry or literature reading? What was your favorite part?"                              
},                               
{                                
    question: "How do you think arts and culture can help us heal and cope with difficult emotions?"                             
},                               
{                                
    question: "How do you think arts and culture can help us build empathy and understanding towards others?"                    
},                               
{                                
    question: "What's the most memorable art or cultural experience you've had with friends or family? Why was it so special?"   
},                               
{                                
    question: "How do you think arts and culture can help us connect with our own heritage and identity?"                        
},                               
{                                
    question: "What's the most impressive work of art you've seen in person?"   
},                               
{                                
    question: "How do you think arts and culture can help us understand our own values and beliefs?"                             
},                               
{                                
    question: "Do you prefer classical music or contemporary music? Why?"       
},                               
{                                
    question: "What's your favorite type of art, and why do you enjoy it?"      
},                               
{                                
    question: "Have you ever tried a new form of art or culture that challenged your assumptions? What was it, and how did you find the experience?"                              
},                               
{                                
    question: "What's the most memorable performance or exhibition you've experienced? Why did it stand out to you?"             
},                               
{                                
    question: "Have you ever learned a new skill or technique related to arts and culture? If so, what was it, and how did you find the learning process?"                        
},                               
{                                
    question: "How do you think arts and culture can help us understand different perspectives and cultures?"                    
},                               
{                                
    question: "How do you think art and culture can help bring people joy and happiness?"  
},                               
{                                
    question: "What's the most important lesson you've learned through your experiences with arts and culture?"                  
},                               
{                                
    question: "What's your favorite form of art (painting, sculpture, photography, etc.) and why?"                               
},                               
{                                
    question: "Have you ever attended a cultural festival or event? If so, which one and what was your favorite part?"           
},                               
{                                
    question: "What's the most inspiring piece of art you've seen recently?"    
},                               
{                                
    question: "Do you have a favorite artist or musician who inspires you? Why?"
},                               
{                                
    question: "What's the most memorable performance or exhibition you've experienced?"    
},                               
{                                
    question: "Have you noticed any differences in how different cultures approach art and expression?"                          
},                               
{                                
    question: "What's your favorite type of music and why do you enjoy it?"     
},                               
{                                
    question: "How does art and culture help us understand our history and heritage?"      
},                               
{                                
    question: "What's the most challenging aspect of creating art or music?"    
},                               
{                                
    question: "Have you ever visited an artist's studio or workshop? If so, what was it like?"                                   
},                               
{                                
    question: "Have you noticed any trends in contemporary art or music?"       
},                               
{                                
    question: "Have you ever participated in a cultural exchange program or study abroad experience? If so, what did you gain from it?"     
},                               
{                                
    question: "What's the most important lesson you've learned through your experiences with art and culture?"                   
},                               
{                                
    question: "What's the most inspiring art project or initiative you've come across? Why did it move you?"                     
},                               
{                                
    question: "What's your favorite form of street art or graffiti, and why do you enjoy it?"                                    
},                               
{                                
    question: "How do you think arts and culture can help us challenge our assumptions and beliefs?"                             
},                               
{                                
    question: "Have you ever learned about a different culture through art or cultural expression? If so, what did you learn, and how did it impact your perspective?"            
},                               
{                                
    question: "What's the most unique or quirky form of art or cultural expression you've encountered? Why did you find it interesting?"    
},                               
{                                
    question: "Have you ever tried a new form of creative expression, such as painting or writing? How did you find the experience?"        
},                               
{                                
    question: "Have you ever attended a film festival or screening? What was your favorite movie, and why did you like it?"      
},                               
{                                
    question: "How does art and culture bring people together across differences?"         
},                               
{                                
    question: "How does art and culture reflect the values of a society?"       
},                               
{                                
    question: "Have you ever tried your hand at creating art or music? If so, what was it like?"                                 
},                               
{                                
    question: "Have you ever attended an opera or ballet performance? If so, what was your impression?"                          
},                               
{                                
    question: "Do you have a favorite historical art or cultural movement (e.g. Renaissance, Impressionism, etc.) and why?"      
},                               
{                                
    question: "How does technology impact the creation and appreciation of art and culture?"},                               
{                                
    question: "What's your favorite aspect of a museum or gallery visit (e.g. exhibits, architecture, etc.)?"                    
},                               
{                                
    question: "Have you ever attended a cultural festival or event? If so, what was your favorite part?"                         
},                               
]


function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
  return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Arts_and_Culture: Topic = {
  topic: 'Arts, and Culture',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}