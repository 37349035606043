// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

const questions = [
    {question: "What's the most fascinating scientific discovery you've heard about recently?"},
{question: "If you could invent something new, what would it be and why?"},
{question: "How do you think technology will change in the next decade?"},
{question: "If you could travel to any celestial body, which one would you choose and why?"},
{question: "Have you ever participated in a citizen science project? What was your experience like?"},
{question: "Do you believe there is life beyond Earth? Why or why not?"},
{question: "How do you think technology has impacted our daily lives in the past decade?"},
{question: "Have you ever visited a robotics or computer science conference? What were some highlights?"},
{question: "How do you think space exploration can help us better understand our own planet?"},
{question: "What's your favorite type of science-related media (e.g., podcasts, YouTube channels, blogs)? Why do you enjoy it?"},
{question: "If you could witness any scientific phenomenon in person, what would it be and why?"},
{question: "What's the most impressive technological achievement you've seen in recent years? Why did it stand out to you?"},
{question: "How do you think we can balance the benefits of technology with potential risks and negative consequences?"},
{question: "What's your take on the ethics of artificial intelligence and machine learning? Concerning or overblown?"},
{question: "How do you think we can encourage more interdisciplinary collaboration between scientists, technologists, and other fields?"},
{question: "Have you ever read a science-related book that changed your perspective or understanding of a topic? Which one and why?"},
{question: "What's your take on the potential for space colonization? Exciting prospect or unrealistic goal?"},
{question: "Have you ever participated in a citizen science project related to space exploration? What was your experience like?"},
{question: "What's the most interesting or unique scientific experiment you've learned about recently? Why did it stand out to you?"},
{question: "Have you ever visited a science festival or event? What was your experience like?"},
{question: "What's your take on the current state of private space companies like SpaceX and Blue Origin? Exciting developments or concerns?"},
{question: "How do you think we can make scientific research more inclusive and diverse?"},
{question: "What's the most significant scientific discovery you've learned about recently? Why is it important?"},
{question: "How do you think we can balance the need for scientific progress with potential environmental and ethical concerns?"},
{question: "Have you ever participated in a space-related online community or forum? What was your experience like?"},
{question: "How do you think we can encourage more people to engage with scientific research and discoveries?"},
{question: "Have you ever visited a planetarium show that featured a virtual tour of the solar system or beyond? What was your experience like?"},
{question: "What's your take on the current state of space telescopes and other observational technology? Exciting developments or concerns?"},
{question: "How do you think we can encourage more interdisciplinary collaboration between scientists, technologists, and artists?"},
{question: "How do you think we can make scientific research more accessible and engaging for the general public?"},
{question: "What's your take on the potential for space-based solar power? Feasible or unrealistic?"},
{question: "How do you think we can encourage more people to pursue careers in space exploration and research?"},
{question: "Have you ever visited a science-themed escape room or immersive experience? What was your experience like?"},
{question: "How do you think we can ensure that scientific research is conducted with the highest ethical standards?"},
{question: "Have you ever participated in a science-related scavenger hunt or treasure search? What was your experience like?"},
{question: "How do you think we can make scientific research more inclusive and diverse?"},
{question: "Have you ever visited a science-themed restaurant or cafe? What was your experience like?"},
{question: "What's your take on the current state of space exploration and colonization efforts by private companies like SpaceX? Exciting developments or concerns?"},
{question: "Have you ever participated in a science-related hackathon or coding challenge? What was your experience like?"},
{question: "What's the most fascinating aspect of space exploration in your opinion? Why is it captivating?"},
{question: "How do you think we can ensure that scientific research is funded and supported adequately for long-term progress?"},
{question: "What's your take on the potential for space-based solar power satellite constellations? Feasible or unrealistic?"},
{question: "How do you think we can encourage more people to pursue careers in space exploration and research through education and outreach?"},
{question: "Have you ever visited a science-themed theme park attraction related to space exploration? What was your experience like?"},
{question: "What's the most interesting or unique scientific instrument or technology you've learned about recently? Why is it significant?"},
{question: "How do you think we can encourage more people to engage with scientific research and discoveries through public outreach and education?"},
{question: "Have you ever participated in a science-related art project or collaboration? What was your experience like?"},
{question: "How do you think we can make scientific research more accessible and engaging for people with disabilities or limited mobility?"},
{question: "Have you ever visited a science-themed restaurant or cafe related to space exploration? What was your experience like?"},
{question: "What's your take on the potential for space-based manufacturing and resource utilization? Feasible or unrealistic?"},
{question: "Have you ever participated in a science-related scavenger hunt or treasure search related to space exploration? What was your experience like?"},
{question: "What's the most fascinating aspect of space exploration in your opinion? Why is it captivating?"},
{question: "Have you ever visited a science-themed escape room or immersive experience related to space exploration? What was your experience like?"},
{question: "Have you ever participated in a science-related game show or trivia night related to space exploration? What was your experience like?"},
{question: "What's your take on the current state of private space companies like SpaceX and Blue Origin? Exciting developments or concerns?"},
{question: "Have you ever visited a science-themed museum exhibit related to space exploration? What was your experience like?"},
{question: "What's the most interesting or unique scientific instrument or technology you've learned about recently? Why is it significant?"},
{question: "Do you prefer reading about scientific breakthroughs online or in print? Why?"},
{question: "Have you ever visited a science museum or planetarium? What was your favorite exhibit/show?"},
{question: "Who is your favorite scientist, and why do you admire them?"},
{question: "How do you think space exploration can benefit humanity in the long run?"},
{question: "Which scientific concept or theory do you find most intriguing, and why?"},
{question: "What's your take on the current state of artificial intelligence research? Exciting or concerning?"},
{question: "If you could have dinner with any historical figure, who would it be and why?"},
{question: "Do you prefer watching documentaries about space or scientific discoveries, or reading books on the topic? Why?"},
{question: "How do you think we can encourage more young people to pursue careers in STEM fields?"},
{question: "Have you ever participated in a science fair or competition? What was your experience like?"},
{question: "Have you ever visited a planetarium or observatory? What was your experience like?"},
{question: "What's the most important scientific question that remains unanswered in your opinion? Why is it significant?"},
{question: "How do you think we can make scientific research and advancements more accessible to the general public?"},
{question: "How do you think we can ensure that scientific research is conducted ethically and responsibly?"},
{question: "How do you think we can encourage more people to pursue careers in space exploration and research?"},
{question: "Have you ever participated in a science-related competition or challenge? What was your experience like?"},
{question: "Have you ever visited a science museum exhibit related to space exploration? What was your experience like?"},
{question: "What's your take on the potential for terraforming other planets or moons in our solar system? Feasible or unrealistic?"},
{question: "What's the most fascinating aspect of space exploration in your opinion? Why is it captivating?"},
{question: "How do you think we can ensure that scientific research is funded and supported adequately?"},
{question: "Have you ever participated in a space-related hackathon or maker challenge? What was your experience like?"},
{question: "What's the most significant scientific breakthrough you've learned about recently? Why is it important?"},
{question: "Have you ever visited a science-themed theme park or attraction? What was your experience like?"},
{question: "Have you ever participated in a science-related game or puzzle hunt? What was your experience like?"},
{question: "What's the most interesting or unique scientific instrument or technology you've learned about recently? Why is it significant?"},
{question: "What's your take on the potential for space-based manufacturing and resource utilization? Feasible or unrealistic?"},
{question: "What's the most significant scientific challenge facing humanity today, in your opinion? Why is it important to address?"},
{question: "How do you think we can encourage more people to engage with scientific research and discoveries through social media?"},
{question: "How do you think we can make scientific research more accessible and engaging for underserved communities?"},
{question: "Have you ever visited a science-themed museum exhibit related to space exploration? What was your experience like?"},
{question: "Have you ever participated in a science-related game show or trivia night? What was your experience like?"},
{question: "What's the most significant scientific discovery you've learned about recently? Why is it important?"},
{question: "How do you think we can make scientific research more inclusive and diverse in terms of representation and perspective?"},
{question: "Have you ever participated in a science-related online community or forum related to space exploration? What was your experience like?"},
{question: "What's your take on the current state of space exploration and colonization efforts by government agencies like NASA? Exciting developments or concerns?"},
{question: "What's the most significant scientific challenge facing humanity today, in your opinion? Why is it important to address?"},
{question: "How do you think we can encourage more people to pursue careers in space exploration and research through mentorship and networking opportunities?"},
{question: "How do you think we can ensure that scientific research is conducted with the highest ethical standards and respect for human subjects and the environment?"},
{question: "How do you think we can make scientific research more accessible and engaging for underserved communities through outreach and education?"},
{question: "What's the most significant scientific breakthrough you've learned about recently? Why is it important?"},
{question: "How do you think we can encourage more people to engage with scientific research and discoveries through social media and online communities?"},
{question: "How do you think we can make scientific research more inclusive and diverse in terms of representation and perspective?"},
{question: "Have you ever participated in a science-related online community or forum related to space exploration? What was your experience like?"}
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const STEM_and_Astronomy: Topic = {
  topic: 'STEM, and Astronomy',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}