import { Topic } from '.'

const questions = [
  {question: "What sparked your interest in DIY electronics and robotics?"},
  {question: "How do you approach problem-solving when working on a DIY project?"},
  {question: "How do you stay organized and keep track of your components and materials when working on a project?"},
  {question: "What advice would you give to someone who is just starting out with DIY electronics and robotics?"},
  {question: "How do you balance the need for creativity and experimentation in your DIY projects with the need for safety and reliability?"},
  {question: "Do you have any favorite online resources or communities for learning about DIY electronics and robotics?"},
  {question: "How do you choose which projects to work on and how do you prioritize your time and resources when working on them?"},
  {question: "Have you ever collaborated with others on a DIY project? If so, what was that experience like for you?"},
  {question: "How do you think DIY electronics and robotics could be used to benefit society or improve people's lives?"},
  {question: "What are some common mistakes you see beginners make when starting out with DIY electronics and robotics?"},
  {question: "What are some of the most important skills or knowledge areas that you think are essential for someone who wants to get into DIY electronics and robotics?"},
  {question: "What are some of the most interesting or unique applications of DIY electronics and robotics that you've seen or heard about?"},
  {question: "Can you share one of your favorite DIY projects that you've worked on?"},
  {question: "Have you ever had to troubleshoot a problem in one of your DIY projects? If so, how did you go about solving it?"},
  {question: "Can you tell us about a particularly challenging project you worked on and how you overcame any obstacles that came up?"}
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const DIY_Electronics_and_Robotics: Topic = {
  topic: 'DIY, Electronics, and Robotics',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}