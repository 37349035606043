import { Topic } from '.'

const questions = [
  {question: "What's your go-to fashion item that always makes you feel confident and put together?"},
  {question: "Have you ever tried a beauty trend that completely flopped? What was it, and what did you learn from the experience?"},
  {question: "How do you approach dressing for different occasions and events? Do you have any specific strategies or rules of thumb?"},
  {question: "Have you ever experimented with bold or daring fashion choices? How did you feel about them, and how were they received by others?"},
  {question: "What's the best beauty advice you've ever received, and has it had a lasting impact on your routine or preferences?"},
  {question: "What's one thing you wish more people knew about fashion and beauty, but might not be aware of?"},
  {question: "What's the most important thing you look for when purchasing clothing or beauty products? Is it quality, affordability, sustainability, or something else entirely?"},
  {question: "Have you ever had a 'fashion disaster' where something went horribly wrong with your outfit or appearance? How did you recover from the situation, and what did you learn from the experience?"},
  {question: "What's one thing you wish you could tell your younger self about fashion and beauty, but didn't have the chance to learn until later in life?"},
  {question: "What's the most memorable outfit you've ever worn, and why did it stand out to you?"},
  {question: "In your opinion, what are some of the biggest fashion and beauty trends of the past decade, and which ones do you think have stood the test of time?"},
  {question: "Have you ever struggled with body image or self-esteem issues related to your appearance? How did you cope with these feelings, and what strategies have helped you come out on the other side?"},
  {question: "Do you have any favorite fashion or beauty influencers, bloggers, or celebrities who inspire your style or choices? Why do you admire them, and what qualities do they possess that you aspire to emulate?"},
  {question: "What's the most challenging aspect of staying up-to-date with fashion and beauty trends for you, and how do you overcome these challenges?"},
  {question: "Have you ever found that your personal style or preferences have influenced your relationships or social interactions in any way? How so, and what did you learn from these experiences?"}
  
]

function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
    return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}


const NUMBER_OF_QUESTIONS = 5
const randomQuestions = selectRandomQuestions(questions, NUMBER_OF_QUESTIONS);


export const Fashion_and_Beauty: Topic = {
  topic: 'Fashion, and Beauty',
  totalQuestions: NUMBER_OF_QUESTIONS,
  questions: randomQuestions, 
}