import { createContext, useContext } from 'react'
import { QuizContextTypes, ScreenTypes } from '../types'

export const initialState: QuizContextTypes = {
  currentScreen: ScreenTypes.SplashScreen,
  setCurrentScreen: () => {},
  quizTopic: 'Arts, and Culture',
  selectQuizTopic: () => {},
  questions: [],
  setQuestions: () => {},
  quizDetails: {
    totalQuestions: 0,
    selectedQuizTopic: 'Arts, and Culture',
  },
}

export const QuizContext = createContext<QuizContextTypes>(initialState)

export function useQuiz() {
  return useContext(QuizContext)
}
